<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      :head-title="this.type=='view'?$t('cip.desk.notice.title.viewHeadTitle'):$t('cip.desk.notice.title.addHeadTitle')"
      @head-save="headSave()"
      @head-save-cancel="headSave(true)"
      @head-cancel="headCancel"
    ></head-layout>
    <el-scrollbar>
      <form-layout
        ref="formLayout"
        :column="formColumn"
        :dataForm="dataForm"
      ></form-layout>
    </el-scrollbar>
  </div>
</template>
<script>
import ServerNameEnum from "@/util/ServerNameEnum";
import HeadLayout from "@/views/components/layout/head-layout";
import FormLayout from "@/views/components/layout/form-layout";
import {update, getNoticeDetail} from "@/api/desk/notice";

export default {
  name: "noticeEdit",
  components: {
    FormLayout,
    HeadLayout
  },
  data() {
    return {
      type: 'view',
      dataForm: {},
    }
  },
  computed: {
    headBtnOptions() {
      let result = [];
      if (['add', 'edit'].includes(this.type)) {
        result.push(
          {
            label: this.$t('cip.cmn.btn.saveBtn'),
            emit: "head-save",
            btnOptType: 'save',
          }
        );
        result.push(
          {
            // todo 保存并返回
            label: this.$t('cip.cmn.btn.saveBackBtn'),
            emit: "head-save-cancel",
          }
        );
      }
      result.push(
        {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
        }
      );
      return result;
    },
    formColumn() {
      return [
        {
          label: this.$t('cip.desk.notice.field.title'),
          prop: 'title',
          labelWidth: 150,
          span: 12,
          placeholder: this.$t(`cip.desk.notice.field.title`),
          disabled: !['add', 'edit'].includes(this.type),
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.notice.field.title'),
              trigger: "blur"
            },
          ],
        },

        {
          label: this.$t('cip.desk.notice.field.category'),
          prop: 'category',
          labelWidth: 150,
          span: 12,
          type: 'select',
          dataType: "string",
          disabled: !['edit', 'add'].includes(this.type),
          dicUrl: `${ServerNameEnum.SERVER_SYSTEM_}/dict/dictionary?code=notice`,
          props: {
            label: "dictValue",
            value: "dictKey"
          },
          rules: [
            {
              required: true,
              message: this.$t('cip.cmn.rule.inputWarning') + this.$t('cip.desk.notice.field.category'),
              trigger: "change"
            },
          ],
        },
        {
          label: this.$t('cip.desk.notice.field.content'),
          prop: "content",
          type: 'content',
          labelWidth: 150,
          span: 24,
          component: 'AvueUeditor',
          disabled: !['edit', 'add'].includes(this.type),
          options: {
            action: `${ServerNameEnum.SERVER_RESOURCE_}/oss/endpoint/put-file`,
            props: {
              res: "data",
              url: "link",
            }
          },
        }
      ]
    },
  },
  created() {
    let {id, type} = this.$route.query;
    this.type = type;
    if (this.type != 'add') {
      this.dataForm.id = id
      this.initData()
    }
  },
  mounted() {
  },
  methods: {
    initData() {
      getNoticeDetail(this.dataForm.id).then(res => {
        const {data} = res.data;
        this.dataForm = data;
      })
    },
    headSave(cancel = false) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          this.$loading();
          update({
            ...this.$refs.formLayout.dataForm,
          })
            .then(res => {
              const {msg, data} = res.data;
              if (res.data.code == 200) {
                this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'))
              } else {
                this.$message.success(msg);
              }
              if (cancel) {
                this.$router.$avueRouter.closeTag();
                this.$router.back();
              } else {
                //刷新数据
                Object.assign(this.dataForm, data)
                this.type = 'edit';
                this.initData()
              }
            })
            .finally(() => {
              this.$loading().close();
              this.$refs.formLayout.$refs.form.allDisabled = false;
            })
        }
      })
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    }
  }
}
</script>

<style scoped>

</style>
